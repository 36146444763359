
<template>
    <div id="add-resume">
        <div class="header">
            <h2>添加履歷</h2>
            <span class="icon close" @click="closeDiloag">x</span>
        </div>
        <div class="content">
            <div class="steps">
                <div :class="{ 'step': true, 'current': step == 1 }"
                    :style="{ 'cursor': step >= 1 ? 'pointer' : undefined }" @click="changeStep(1)">
                    <div class="line"></div>
                    <span class="count">1</span>
                    <div class="desc">基本信息</div>
                </div>
                <div :class="{ 'step': true, 'current': step == 2 }"
                    :style="{ 'cursor': step >= 2 ? 'pointer' : undefined }" @click="changeStep(2)">
                    <div class="line"></div>
                    <span class="count">2</span>
                    <div class="desc">期望職位</div>
                </div>
                <div :class="{ 'step': true, 'current': step == 3 }"
                    :style="{ 'cursor': step >= 3 ? 'pointer' : undefined }" @click="changeStep(3)">
                    <div class="line"></div>
                    <span class="count">3</span>
                    <div class="desc">工作經驗</div>
                </div>
                <div :class="{ 'step': true, 'current': step == 4 }" @click="changeStep(4)">
                    <div class="line"></div>
                    <span class="count">4</span>
                    <div class="desc">學歷</div>
                </div>
            </div>
            <div class="content-wrapper">
                <div class="tabview" v-if="step == 1">
                    <div class="field-list">
                        <div class="field-item">
                            <div class="field-label">
                                姓名
                            </div>
                            <div class="field-value">
                                <input type="text" :value="userInfo.clientname || ''" readonly>
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                性別
                            </div>
                            <div class="field-value">
                                <input type="text" :value="userInfo.clientsex == 1 ? '男' : '女'" readonly>
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                郵箱<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <input type="text" v-model="basicInfo.email" placeholder="請輸入您的郵箱">
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                電話<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <input type="text" v-model="basicInfo.mobile" placeholder="請輸入您的電話">
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                學歷<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <select v-model="basicInfo.highestEduLevel" placeholder="請選擇">
                                    <option value="" disabled selected>請選擇</option>
                                    <option value="1">初中及以下</option>
                                    <option value="2">中專/中技</option>
                                    <option value="3">高中</option>
                                    <option value="4">大專</option>
                                    <option value="5">本科</option>
                                    <option value="6">碩士</option>
                                    <option value="7">博士</option>
                                </select>
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                相關工作經驗<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <select v-model="basicInfo.yearOfWorkExp" placeholder="請選擇">
                                    <option value="" disabled selected>請選擇</option>
                                    <option value="1" v-for="(d, i) in workYearList" :key="i" v-text="`${d}年`" :value="d">
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                中醫師註冊編號（<a href="https://os.ectcm.com" target="_blank" style="text-decoration: underline;">中醫診所管理系統</a> 註冊的中醫師編號）
                            </div>
                            <div class="field-value">
                                <input type="text" :value="basicInfo.doctorNo" readonly/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabview" v-if="step == 2">
                    <div class="operate absolute">
                        <div class="btn btn-add" @click="addDesireJob">添加</div>
                    </div>
                    <div class="field-list" v-for="(item, i) in desireJobs" :key="i">
                        <h2 class="title" v-text="`期望職位（${numberfilter(i + 1)}）`"></h2>
                        <div class="field-item full-line">
                            <div class="field-label">
                                求職類型<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <select v-model="item.type" placeholder="請選擇">
                                    <option value="" disabled selected>請選擇</option>
                                    <option value="1">全職</option>
                                    <option value="2">兼職</option>
                                    <option value="3">實習</option>
                                </select>
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                期望職位<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <select v-model="item.desireJob" placeholder="請選擇">
                                    <option value="" disabled selected>請選擇</option>
                                    <option value="1">註冊中醫師</option>
                                    <option value="2">表列中醫師</option>
                                    <option value="3">診所助理</option>
                                    <option value="4">配藥員</option>
                                    <option value="5">講師</option>
                                    <option value="6">推拿師</option>
                                    <option value="7">臨時講座講者</option>
                                    <option value="8">義診醫師</option>
                                    <option value="9">義診助手</option>
                                    <option value="10">外展醫師</option>
                                    <option value="11">學徒</option>
                                </select>
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                期望薪資<span class="required">*</span>
                            </div>
                            <div class="range-value">
                                <div class="field-value">
                                    <select v-model="salaryList[i].salaryLeft" @change="onSalaryLeftChange($event)"
                                        placeholder="請選擇">
                                        <option value="" disabled selected>請選擇</option>
                                        <option v-for="(item, index) in salaryLeftList" :key="index" :value="item"
                                            v-text="item"></option>
                                    </select>
                                </div>
                                <div class="label" v-if="salaryList[i].salaryLeft != '面議'">至</div>
                                <div class="field-value" v-if="salaryList[i].salaryLeft != '面議'">
                                    <select v-model="salaryList[i].salaryRight" placeholder="請選擇"
                                        @change="onSalaryRigthChange($event)">
                                        <option value="" disabled selected>請選擇</option>
                                        <option v-for="(item, index) in salaryList[i].salaryRightList" :key="index"
                                            :value="item" v-text="item"></option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="operate">
                            <div class="btn btn-del" v-if="desireJobs.length > 1" @click="() => deleteDesireJob(i)">删除</div>
                        </div>
                    </div>
                </div>
                <div class="tabview" v-if="step == 3">
                    <div class="operate absolute">
                        <div class="btn btn-add" @click="addWorkExperience">添加</div>
                    </div>
                    <div class="field-list" v-for="(item, i) in workExperiences" :key="i">
                        <h2 class="title" v-text="`工作經驗（${numberfilter(i + 1)}）`"></h2>
                        <div class="field-item full-line">
                            <div class="field-label">
                                公司名稱<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <input type="text" v-model="item.company" placeholder="請輸入公司名稱">
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                是否在職<span class="required">*</span>
                            </div>
                            <div class="field-value radio">
                                <van-radio-group v-model="item.toPresent">
                                    <van-radio name="1">是</van-radio>
                                    <van-radio name="">否</van-radio>
                                </van-radio-group>
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                職位<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <select v-model="item.position" placeholder="請選擇">
                                    <option value="" disabled selected>請選擇</option>
                                    <option value="1">註冊中醫師</option>
                                    <option value="2">表列中醫師</option>
                                    <option value="3">診所助理</option>
                                    <option value="4">配藥員</option>
                                    <option value="5">講師</option>
                                    <option value="6">推拿師</option>
                                    <option value="7">臨時講座講者</option>
                                    <option value="8">義診醫師</option>
                                    <option value="9">義診助手</option>
                                    <option value="10">外展醫師</option>
                                    <option value="11">學徒</option>
                                </select>
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                在職時間段<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <div class="row">
                                    <div class="col" v-text="item.startDate || '開始日期'" @click="onWorkDateRangeChange(item, 1)"></div>
                                    <span class="divider">~</span>
                                    <div class="col" v-text="item.endDate || '結束日期'" @click="onWorkDateRangeChange(item, 2)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                職位描述<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <textarea type="text" cols="120" rows="4" v-model="item.jobDuties"
                                    placeholder="請輸入工作描述"></textarea>
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                過往使用系統
                            </div>
                            <div class="field-value">
                                <textarea cols="120" rows="4" v-model="item.apps" placeholder="請輸入過往使用系統"></textarea>
                            </div>
                        </div>
                        <div class="operate">
                            <div class="btn btn-del" v-if="workExperiences.length > 1" @click="() => deleteWorkExperience(i)">删除
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabview" v-if="step == 4">
                    <div class="operate absolute">
                        <div class="btn btn-add" @click="addEducation">添加</div>
                    </div>
                    <div class="field-list" v-for="(item, i) in educations" :key="i">
                        <h2 class="title" v-text="`學歷（${numberfilter(i + 1)}）`"></h2>
                        <div class="field-item full-line">
                            <div class="field-label">
                                學校名稱<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <input type="text" v-model="item.institutionName" placeholder="請輸入學校名稱">
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                學歷<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <select v-model="item.education" placeholder="請選擇">
                                    <option value="" disabled selected>請選擇</option>
                                    <option value="1">初中及以下</option>
                                    <option value="2">中專/中技</option>
                                    <option value="3">高中</option>
                                    <option value="4">大專</option>
                                    <option value="5">本科</option>
                                    <option value="6">碩士</option>
                                    <option value="7">博士</option>
                                </select>
                            </div>
                        </div>
                        <div class="field-item">
                            <div class="field-label">
                                專業<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <input type="text" v-model="item.profession" placeholder="請輸入專業">
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                時間段<span class="required">*</span>
                            </div>
                            <div class="field-value">
                                <div class="row">
                                    <div class="col" v-text="item.startTime || '開始日期'" @click="onEducationDateRangeChange(item, 1)"></div>
                                    <span class="divider">~</span>
                                    <div class="col" v-text="item.endTime || '結束日期'" @click="onEducationDateRangeChange(item, 2)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="field-item full-line">
                            <div class="field-label">
                                資格證書
                            </div>
                            <div class="field-value">
                                <textarea type="text" cols="120" rows="4" v-model="item.credentials" placeholder="請輸入工作描述"></textarea>
                            </div>
                            <div class="btn-add-credentials-file" @click="uploadCredentialsFile(i)">添加資格證書附件</div>
                        </div>
                        <div class="operate">
                            <div class="btn btn-del" v-if="educations.length > 1" @click="() => deleteEducation(i)">删除
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-step" v-text="stepList[step - 1]" @click="submit"></div>
        </div>
        <van-popup
                v-model="show"
                position="bottom"
                :style="{ height: '30%' }"
            >
                <van-datetime-picker
                    v-model="currentDate"
                    type="year-month"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @cancel="show=false"
                    @confirm="onDateTimePickConfirm"
                />
            </van-popup>
            <form ref="form" style="display: none;">
                <input type="file" ref="credentialsFile" id="credentialsFile" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" @change="onCredentialsFileChanged"/>
            </form>
        <loading v-if="loading" />
    </div>
</template>
<script>
import Loading from '@/layouts/Loading.vue'
import moment from 'moment'
export default {
    name: 'AddResume',
    components: {
        Loading
    },
    props: {
        onClose: Function,
    },
    mounted() {
        for (let i = 0; i <= 60; i++) {
            this.workYearList.push(i)
        }
    },
    data() {
        return {
            show: false,
            rangeDateTag: -1,
            dateRangeType: undefined,
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            loading: false,
            userInfo: this.$store.getters.userInfo,
            step: 1,
            workYearList: [],
            salaryLeftList: ['面議', '1k', '2k', '3k', '4k', '5k', '6k', '7k', '8k', '9k', '10k', '11k', '12k', '13k', '14k', '15k', '16k', '17k', '18k', '19k', '20k', '21k', '22k', '23k', '24k', '25k', '26k', '27k', '28k', '29k', '30k', '35k', '40k', '45k', '50k', '55k', '60k', '65k', '70k', '75k', '80k', '85k', '90k', '95k', '100k'],
            salaryList: [
                {
                    salaryRightList: [],
                    salaryLeft: '',
                    salaryRight: '',
                }
            ],
            stepList: [
                '下一步',
                '下一步',
                '下一步',
                '提交',
            ],
            jobTypeList: [
                { name: '全職', value: '1' },
                { name: '兼職', value: '2' },
                { name: '實習', value: '3' },
            ],
            positionList: [
                { name: '註冊中醫師', value: '1' },
                { name: '表列中醫師', value: '2' },
                { name: '診所助理', value: '3' },
                { name: '配藥員', value: '4' },
                { name: '講師', value: '5' },
                { name: '推拿師', value: '6' },
                { name: '臨時講座講者', value: '7' },
                { name: '義診醫師', value: '8' },
                { name: '義診助手', value: '9' },
                { name: '外展醫師', value: '10' },
                { name: '學徒', value: '11' },
            ],
            highestEduLevelList: [
                { name: '初中及以下', value: '1' },
                { name: '中专/中技', value: '2' },
                { name: '高中', value: '3' },
                { name: '大专', value: '4' },
                { name: '本科', value: '5' },
                { name: '硕士', value: '6' },
                { name: '博士', value: '7' },
            ],
            basicInfo: {
                doctorNo: (this.$store.getters.userInfo && this.$store.getters.userInfo.doctorno) || '',
                yearOfWorkExp: '',
                highestEduLevel: '',
                email: this.$store.getters.userInfo && this.$store.getters.userInfo.clientemail,
                mobile: this.$store.getters.userInfo && this.$store.getters.userInfo.clientmobile,
            },
            desireJobsIndex: 0,
            workExperiencesIndex: 0,
            educationsIndex: 0,
            desireJobs: [
                {
                    desireJob: '',
                    desireSalary: undefined,
                    type: '',
                }
            ],
            mWorkExperience: undefined,
            workExperiences: [
                {
                    company: undefined, //公司名称
                    position: '', //职位
                    toPresent: undefined, //当前是否还在职
                    jobDuties: undefined, //职位描述
                    startDate: '', //开始时间
                    endDate: '', //结束时间 
                    dateList: ['', ''],
                }
            ],
            mEducation: undefined,
            educations: [
                {
                    credentials: undefined, //资格证书
                    education: '', //学历
                    institutionName: undefined, //机构(学校)名称
                    profession: undefined, //专业
                    startTime: '', //开始时间
                    endTime: '', //结束时间
                    dateList: ['', ''],
                }
            ],
        }
    },
    methods: {
        moment,
        uploadCredentialsFile(index){
            if(this.loading) return
            this.credentialsIndex = index
            this.$refs.credentialsFile.click && this.$refs.credentialsFile.click()
        },
        onCredentialsFileChanged(v){
            if(!v) return
            if(this.loading) return
            var files = this.$refs.credentialsFile.files
            if(!files || !files.length) return
            this.loading = true
            var formData = new FormData()
            formData.append("files", files[0]);
            this.$store.dispatch('uploadFile', formData)
            .then((rs)=>{
                var url = rs[0]
                this.educations[this.credentialsIndex].credentials = `${this.educations[this.credentialsIndex].credentials || ''} \r\n${url}`
            }).catch(error => {
                this._toast.warning(this, error || '上傳失敗')
            }).finally(()=>{
                this.$refs.credentialsFile.value = ""
                this.loading = false
            });
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }
            return val;
        },
        numberfilter(num) {
            const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] // changeNum[0] = "零"
            const unit = ['', '十', '百']
            num = parseInt(num)
            const getWan = (temp) => {
                const strArr = temp.toString().split('').reverse()
                let newNum = ''
                for (var i = 0; i < strArr.length; i++) {
                    newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
                }
                return newNum
            }
            const overWan = Math.floor(num / 100)
            let noWan = num % 100
            if (noWan.toString().length < 2) noWan = '0' + noWan
            let strr = (overWan ? getWan(overWan) + '百' + getWan(noWan) : getWan(num))
            let showNum = "";
            if (strr.split('')[0] == '一') {
                if (strr == '一') {
                    showNum = strr.substring(0);
                } else {
                    showNum = strr.substring(1);
                }
            } else {
                showNum = overWan ? getWan(overWan) + '百' + getWan(noWan) : getWan(num);
            }
            return showNum;
        },
        getJobType(v) {
            var text = v || ''
            var model = this.jobTypeList.find((v) => v.value == text)
            if (model) {
                return model.name
            }
            return v
        },
        position(v) {
            var text = v || ''
            var model = this.positionList.find((v) => v.value == text)
            if (model) {
                return model.name
            }
            return ''
        },
        deleteEducation(index) {
            this.educations.splice(index, 1)
            this.educationsIndex--
        },
        addEducation() {
            if (!(this.educations[this.educationsIndex].institutionName || '').trim()) {
                this._toast.warning(this, '請輸入學校名稱')
                return
            }
            if (!this.educations[this.educationsIndex].education) {
                this._toast.warning(this, '請選擇學歷')
                return
            }
            if (!(this.educations[this.educationsIndex].profession || '').trim()) {
                this._toast.warning(this, '請輸入專業')
                return
            }
            if (!this.educations[this.educationsIndex].startTime || !this.educations[this.educationsIndex].endTime) {
                this._toast.warning(this, '請選擇在校時間段')
                return
            }
            this.educations.push({
                credentials: undefined, //资格证书
                education: '', //学历
                institutionName: undefined, //机构(学校)名称
                profession: undefined, //专业
                startTime: '', //开始时间
                endTime: '', //结束时间
                dateList: ['', ''],
            })
            this.educationsIndex++
        },
        deleteDesireJob(index) {
            this.desireJobsIndex--
            this.desireJobs.splice(index, 1)
        },
        addDesireJob() {
            if (!this.desireJobs[this.desireJobsIndex].type) {
                this._toast.warning(this, '請選擇求職類型')
                return
            }
            if (!this.desireJobs[this.desireJobsIndex].desireJob) {
                this._toast.warning(this, '請選擇期望職位')
                return
            }
            var text = this.desireJobs[this.desireJobsIndex].desireJob
            if(this.desireJobs.filter((e)=>e.desireJob==text).length > 1) {
                this._toast.warning(this, '期望職位不能重複')
                return
            }

            if (!this.desireJobs[this.desireJobsIndex].desireSalary) {
                this._toast.warning(this, '請選擇期望薪資')
                return
            }
            this.desireJobsIndex++
            this.salaryList.push({
                salaryRightList: []
            })
            this.desireJobs.push({
                desireJob: '',
                desireSalary: undefined,
                type: '',
            })
        },
        deleteWorkExperience(index) {
            this.workExperiences.splice(index, 1)
            this.workExperiencesIndex--
        },
        addWorkExperience() {
            if (!(this.workExperiences[this.workExperiencesIndex].company || '').trim()) {
                this._toast.warning(this, '請輸入公司名稱')
                return
            }
            if (this.workExperiences[this.workExperiencesIndex].toPresent == undefined) {
                this._toast.warning(this, '請選擇是否在職')
                return
            }
            if (!this.workExperiences[this.workExperiencesIndex].position) {
                this._toast.warning(this, '請選擇職位')
                return
            }
            if (!this.workExperiences[this.workExperiencesIndex].startDate || !this.workExperiences[this.workExperiencesIndex].endDate) {
                this._toast.warning(this, '請選擇在職時間段')
                return
            }
            if(!this.workExperiences[this.workExperiencesIndex].jobDuties){
                this._toast.warning(this, '請輸入職位描述')
                return
            }
            this.workExperiences.push({
                company: undefined, //公司名称
                position: '', //职位
                toPresent: undefined, //当前是否还在职
                jobDuties: undefined, //职位描述
                startDate: '', //开始时间
                endDate: '', //结束时间 
            })
            this.workExperiencesIndex++
        },
        onDateTimePickConfirm(d) {
            this.show = false
            if (this.dateRangeType == 'educations') {
                if (this.rangeDateTag == 1) {
                    this.mEducation.startTime = this.moment(d).format("YYYY-MM")
                } else if (this.rangeDateTag == 2) {
                    this.mEducation.endTime = this.moment(d).format("YYYY-MM")
                }
            } else if (this.dateRangeType == 'workExperiences') {
                if (this.rangeDateTag == 1) {
                    this.mWorkExperience.startDate = this.moment(d).format("YYYY-MM")
                } else if (this.rangeDateTag == 2) {
                    this.mWorkExperience.endDate = this.moment(d).format("YYYY-MM")
                }
            }
            this.dateRangeType = undefined
            this.rangeDateTag = -1

        },
        closeDiloag() {
            this.onClose && this.onClose()
        },
        onWorkDateRangeChange(item, v) {
            this.mWorkExperience = item
            this.show = true
            this.dateRangeType = 'workExperiences'
            this.rangeDateTag = v
            if (v == 1) {
                //开始
                if (item.endDate) {
                    this.maxDate = new Date(item.endDate)
                    this.minDate = new Date(1900, 0, 1)
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            } else if (v == 2) {
                //结束
                if (item.startDate) {
                    this.minDate = new Date(item.startDate)
                    this.maxDate = new Date()
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            }

        },
        onEducationDateRangeChange(item, v) {
            this.mEducation = item
            this.show = true
            this.dateRangeType = 'educations'
            this.rangeDateTag = v
            if (v == 1) {
                //开始
                if (item.endTime) {
                    this.maxDate = new Date(item.endTime)
                    this.minDate = new Date(1900, 0, 1)
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            } else if (v == 2) {
                //结束
                if (item.startTime) {
                    this.minDate = new Date(item.startTime)
                    this.maxDate = new Date()
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            }
        },
        onSalaryLeftChange: function (e) {
            var value = e.target.value;
            if (value == '面議') {
                this.desireJobs[this.desireJobsIndex].desireSalary = '面議'
                return
            }
            this.desireJobs[this.desireJobsIndex].desireSalary = undefined;
            const start = this.salaryLeftList.indexOf(value)
            var end = start + 6;
            if (end >= this.salaryLeftList.length) {
                end = this.salaryLeftList.length - 1;
            }
            this.salaryList[this.desireJobsIndex].salaryRightList.length = 0;
            for (var i = start + 1; i <= end; i++) {
                this.salaryList[this.desireJobsIndex].salaryRightList.push(this.salaryLeftList[i])
            }
            if (!this.salaryList[this.desireJobsIndex].salaryRightList.length) {
                this.salaryList[this.desireJobsIndex].salaryRightList.push(this.salaryLeftList[start])
            }
        },
        onSalaryRigthChange: function (e) {
            var value = e.target.value;
            if (value) {
                this.desireJobs[this.desireJobsIndex].desireSalary = `${this.salaryList[this.desireJobsIndex].salaryLeft}~${this.salaryList[this.desireJobsIndex].salaryRight}`;
            }
        },
        changeStep: function (step) {
            if (this.step > step) {
                this.step = step
            }
        },
        submit: function () {
            if (this.loading) return
            if (this.step == 1) {
                if (!(this.basicInfo.email || '').trim()) {
                    this._toast.warning(this, '請輸入郵箱')
                    return
                }
                if (!(this.basicInfo.mobile || '').trim()) {
                    this._toast.warning(this, '請輸入電話')
                    return
                }
                if (!this.basicInfo.highestEduLevel) {
                    this._toast.warning(this, '請選擇學歷')
                    return
                }
                if (this.basicInfo.yearOfWorkExp === '') {
                    this._toast.warning(this, '請選擇相關工作經驗')
                    return
                }
            } else if (this.step == 2) {
                for (let i = 0; i < this.desireJobs.length; i++) {
                    if (!this.desireJobs[i].type) {
                        this._toast.warning(this, '請選擇求職類型')
                        return
                    }
                    if (!this.desireJobs[i].desireJob) {
                        this._toast.warning(this, '請選擇期望職位')
                        return
                    }
                    var text = this.desireJobs[i].desireJob
                    if(this.desireJobs.filter((e)=>e.desireJob==text).length > 1) {
                        this._toast.warning(this, '期望職位不能重複')
                        return
                    }
            
                    if (!this.desireJobs[i].desireSalary) {
                        this._toast.warning(this, '請選擇期望薪資')
                        return
                    }
                }
            } else if (this.step == 3) {
                for (let i = 0; i < this.workExperiences.length; i++) {
                    if (!(this.workExperiences[i].company || '').trim()) {
                        this._toast.warning(this, '請輸入公司名稱')
                        return
                    }
                    if (this.workExperiences[i].toPresent == undefined) {
                        this._toast.warning(this, '請選擇是否在職')
                        return
                    }
                    if (!this.workExperiences[i].position) {
                        this._toast.warning(this, '請選擇職位')
                        return
                    }
                    if (!this.workExperiences[i].startDate || !this.workExperiences[i].endDate) {
                        this._toast.warning(this, '請選擇在職時間段')
                        return
                    }
                    if(!this.workExperiences[i].jobDuties){
                        this._toast.warning(this, '請輸入職位描述')
                        return
                    }
                }
            } else if (this.step == 4) {
                console.dir(this.educations)
                for (let i = 0; i < this.educations.length; i++) {
                    if (!(this.educations[i].institutionName || '').trim()) {
                        this._toast.warning(this, '請輸入學校名稱')
                        return
                    }
                    if (!this.educations[i].education) {
                        this._toast.warning(this, '請選擇學歷')
                        return
                    }
                    if (!(this.educations[i].profession || '').trim()) {
                        this._toast.warning(this, '請輸入專業')
                        return
                    }
                    if (!this.educations[i].startTime || !this.educations[i].endTime) {
                        this._toast.warning(this, '請選擇在校時間段')
                        return
                    }
                }
            }

            this.step++;
            if (this.step > this.stepList.length) {
                this.step = this.stepList.length
                //提交
                this.loading = true
                var params = {
                    title: '我的履歷',
                    uid: this.$store.getters.clientId,
                    ...this.basicInfo,
                    privacyStatus: 1,
                    yearOfWorkExp: parseInt(this.basicInfo.yearOfWorkExp),
                    desireJobs: this.desireJobs,
                    educations: this.educations.map((map)=>{
                        return {
                            ...map,
                            "startTime": `${map.startTime}-01`,
                            "endTime": `${map.endTime}-01`,
                        } 
                    }),
                    workExperiences: this.workExperiences.map((map) => {
                        return {
                            ...map,
                            "startDate": `${map.startDate}-01`,
                            "endDate": `${map.endDate}-01`,
                            "toPresent": !!map.toPresent
                        }
                    })
                }
                this.$store.dispatch('addResume', params)
                    .then((rs) => {
                        this.loading = false
                        this._toast.success(this, '添加履歷成功！')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1500)
                    }).catch((error) => {
                        this.loading = false
                        this._toast.warning(this, error || '操作失敗')
                    })
            }
        }
    }
};
</script>
<style>
#add-resume {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 150px 20px;
    background-color: #fff;
    border-radius: 12px;
    padding: 0px 8px;
    max-height: 80vh;
    box-sizing: border-box;
    width: calc(100vw - 40px);
    height: 80%;
    overflow: hidden;
}

#add-resume ::-webkit-textarea-placeholder,
#add-resume ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
    font-size: 28px !important;
}

#add-resume ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
    font-size: 28px !important;
}

#add-resume :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
    font-size: 28px !important;
}

#add-resume .header {
    position: relative;
    height: 104px;
    line-height: 104px;
    border-bottom: 1px solid #f3f3f3;
    text-align: center;
    font-size: 42px;
    font-weight: bold;
}

#add-resume .header .icon.close {
    font-family: sans-serif;
    position: absolute;
    right: 16px;
    top: 16px;
    height: 72px;
    width: 72px;
    line-height: 72px;
    display: inline-block;
    color: #999;
    background-color: #fff;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
}

#add-resume .header .icon.close:active {
    background-color: #fff;
}

#add-resume .content {
    height: 90%;
    display: flex;
    flex-direction: column;
}

#add-resume .content>.steps {
    display: flex;
    flex-direction: row;
    height: 172px;
}
#add-resume .content > .content-wrapper {
    flex: 1;
    overflow-y: scroll;
} 

#add-resume .content>.steps>.step {
    position: relative;
    flex: 1;
}

#add-resume .content>.steps>.step>.line {
    width: 100%;
    height: 8px;
    background-color: #E8E8E8;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

#add-resume .content>.steps>.step.current>.line {
    background-color: #36C4D0
}

#add-resume .content>.steps>.step>.count {
    background-color: #E8E8E8;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    text-align: center;
    line-height: 52px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

#add-resume .content>.steps>.step.current>.count {
    background-color: #36C4D0
}

#add-resume .content>.steps>.step>.desc {
    text-align: center;
    line-height: 160px;
    padding-top: 52px;
    color: #E8E8E8;
    font-size: 28px;
    font-weight: bold;
    user-select: none;
}

#add-resume .content>.steps>.step.current>.desc {
    color: #36C4D0;
    font-size: 32px;
}

#add-resume .tabview {
    min-height: 500px !important;
    padding: 0px !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: center !important;
}

#add-resume .tabview .field-list {
    display: flex !important;
    flex-wrap: wrap !important;
    padding: 24px !important;
    box-sizing: border-box !important;
    justify-content: space-between !important;
}

#add-resume .field-list .title {
    color: #ccc;
    font-size: 36px;
    font-weight: 500;
    user-select: none;
}

#add-resume .field-item {
    color: #495060;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(50% - 12px);
    font-size: 28px !important;
    position: relative;
}

#add-resume .field-item .btn-add-credentials-file {
    height: 60px;
    line-height: 60px;
    text-decoration: underline;
    cursor: pointer;
}

#add-resume .field-item.field-item.full-line {
    width: 100%;
}

#add-resume .field-item .range-value {
    display: flex;
    flex-direction: row;
}

#add-resume .field-item .range-value .label {
    margin-top: 20px !important;
    height: 80px !important;
    line-height: 80px !important;
    width: 100px !important;
    text-align: center !important;
}

#add-resume .field-item .field-label .required {
    color: #f00;
}

#add-resume .field-item .field-value {
    margin-top: 8px;
    min-width: 100px;
    min-height: 40px;
    border: 1px solid #e3e7ed;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    font-size: 28px;
}

#add-resume .field-item .field-value .row {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#add-resume .field-item .field-value .row .col {
    flex: 1;
    text-align: center;
}

#add-resume .field-item .field-value.radio {
    border-color: transparent;
}

#add-resume .field-item .range-value .field-value {
    flex: 1;
}

#add-resume .field-item .field-value>input {
    background-color: transparent;
    width: 100%;
    padding: 12px 8px;
    box-sizing: border-box;
}

#add-resume .field-item .field-value>input:read-only {
    background-color: #f3f3f3;
}

#add-resume .field-item .field-value>select {
    background-color: transparent;
    outline: none;
    border: none;
    display: block;
    width: 100%;
    padding: 12px 8px;
    box-sizing: border-box;
}

#add-resume .field-item .field-value>textarea {
    background-color: transparent;
    outline: none;
    border: none;
    display: block;
    width: 100%;
    padding: 12px 8px;
    box-sizing: border-box;
    word-wrap: break-word;      /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap;      /* current browsers */
}

#add-resume .field-item .field-value.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#add-resume .field-item .field-value.row .divider {
    display: inline-block;
    width: 40px;
    text-align: center;
}

#add-resume .field-item .field-value .field-value-item {
    flex: 1;
    text-align: center;
}

#add-resume .field-item .field-value .field-value-item.hint {
    opacity: 0.7;
}

#add-resume .btn-step {
    border-radius: 4px !important;
    background-color: #00c2b3 !important;
    height: 66px !important;
    line-height: 66px !important;
    width: 80vw !important;
    text-align: center !important;
    margin: 50px auto !important;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    user-select: none !important;
    box-shadow: 2px 2px 5px #ccc !important;
}

#add-resume .btn-step:active {
    box-shadow: none;
}

#add-resume .ant-calendar-picker {
    width: 100%;
}

#add-resume .ant-input {
    height: unset;
    border: none;
    height: 43px;
    box-sizing: border-box;
}

#add-resume .ant-radio-group {
    line-height: 43px;
    height: 43px;
    padding: 0px 8px;
}

#add-resume .operate {
    width: 100%;
    display: flex;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

#add-resume .operate.absolute {
    position: absolute;
    top: 20px;
    right: 0;
}

#add-resume .operate .btn {
    margin: 0;
    padding: 18px 30px;
    line-height: 1;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #e3e7ed;
    color: #414a60;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    border-radius: 0;
    font-size: 24px;
    box-sizing: border-box;
    transition: background-color .1s linear,box-shadow .1s linear;
    outline: none;
    user-select: none;
}

#add-resume .operate .btn-del {
    margin-left: 20px;
    min-width: 120px;
    background-color: #fff;
}

#add-resume .operate .btn-add {
    margin-left: 20px;
    margin-right: 42px;
    min-width: 120px;
    background-color: #6adbcf;
    border-color: #6adbcf;
    border-width: 1px;
    color: #fff;
    box-sizing: border-box;
}
</style>