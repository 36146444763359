<template>
    <div id="my-resume">
        <div class="edit-menu" v-if="hasResume == true">
            <div class="edit-menu-box privacy-box">
                <div class="menu-head">
                    <h3 class="menu-title">隐私设置</h3>
                </div>
                <div class="menu-content">
                    <div class="menu-item">
                        <div class="title">履歷開放:</div>
                        <div class="value" @click="changeResumeStatus">
                            <span :class="{'switch': true, 'on': privacyOn}"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="edit-menu-box file-box">
                <div class="menu-head">
                    <h3 class="menu-title">附件管理</h3>
                </div>
                <div class="menu-content">
                    <div class="menu-item menu-file" v-for="(item,i) in resumeData.attachments" :key="i">
                        <a class="title" :title="item.title" v-text="item.title" :href="item.url" target="_blank"></a>
                        <span class="value">
                            <span class="btn-resume-file update" title="重命名" @click="renameResumeFile(i)"></span>
                            <span class="btn-resume-file" title="刪除" @click="deleteResumeFile(i)"></span>
                        </span>
                    </div>
                    <div class="btn-add-resume-file" @click="uploadFile">添加附件履歷</div>
                </div>
            </div>
        </div>
		<div class="content">
			<div class="resume-content" v-if="hasResume == true">
				<div class="resume-box-header">
					<h2>我的在線履歷</h2>
					<!-- <div class="header-right">
						<a href="javascript:;" @click="deleteResume">刪除</a>
					</div> -->
				</div>
				<div class="resume-content-box">
					<div class="resume-box" v-if="!basicInfoEdit">
						<h2 class="resume-box-title" v-text="userInfo.clientname || ''"></h2>
						<div class="resume-box-content">
							<div class="row">
								<div class="field-read-item">
									<span class="field-title">相關工作經驗:</span>
									<div class="field-value" v-text="`${yearOfWorkExp}`"></div>
								</div>
								<span class="divider"></span>
								<div class="field-read-item">
									<span class="field-title">學歷:</span>
										<div class="field-value" v-text="highestEduLevel(resumeData.resume.highestEduLevel)"></div>
								</div>
								<span class="divider"></span>
								<div class="field-read-item">
									<span class="field-title">電話:</span>
									<div class="field-value" v-text="mobile"></div>
								</div>
							</div>
						</div>
						<div class="resume-box-edit">
							<a href="javascript:;" class="btn-edit" @click="updateBasicInfo"><span>編輯</span></a>
						</div>
					</div>
					<div class="tabview" v-if="basicInfoEdit">
						<div class="header">
							<h2 class="title" v-text="editTitle"></h2>
						</div>
						<div class="field-list">
							<div class="field-item">
								<div class="field-label">
									姓名
								</div>
								<div class="field-value">
									<input type="text" :value="userInfo.clientname || ''" readonly>
								</div>
							</div>
							<div class="field-item">
								<div class="field-label">
									性別
								</div>
								<div class="field-value">
									<input type="text" :value="userInfo.clientsex == 1 ? '男' : '女'" readonly>
								</div>
							</div>
							<div class="field-item">
								<div class="field-label">
									郵箱<span class="required">*</span>
								</div>
								<div class="field-value">
									<input type="text" v-model="addInfo.basicInfo.email" placeholder="請輸入您的郵箱">
								</div>
							</div>
							<div class="field-item">
								<div class="field-label">
									電話<span class="required">*</span>
								</div>
								<div class="field-value">
									<input type="text" v-model="addInfo.basicInfo.mobile" placeholder="請輸入您的電話">
								</div>
							</div>
							<div class="field-item">
								<div class="field-label">
									學歷<span class="required">*</span>
								</div>
								<div class="field-value">
									<select v-model="addInfo.basicInfo.highestEduLevel" placeholder="請選擇">
										<option value="" disabled selected>請選擇</option>
										<option value="1">初中及以下</option>
										<option value="2">中專/中技</option>
										<option value="3">高中</option>
										<option value="4">大專</option>
										<option value="5">本科</option>
										<option value="6">碩士</option>
										<option value="7">博士</option>
									</select>
								</div>
							</div>
							<div class="field-item">
								<div class="field-label">
									相關工作經驗<span class="required">*</span>
								</div>
								<div class="field-value">
									<select v-model="addInfo.basicInfo.yearOfWorkExp" placeholder="請選擇">
										<option value="" disabled selected>請選擇</option>
										<option value="1" v-for="(d,i) in addInfo.workYearList" :key="i" v-text="`${d}年`" :value="d"></option>
									</select>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									中醫師註冊編號（<a href="https://os.ectcm.com" target="_blank" style="text-decoration: underline;">中醫診所管理系統</a> 註冊的中醫師編號）
								</div>
								<div class="field-value">
									<input type="text" :value="addInfo.basicInfo.doctorNo" readonly/>
								</div>
							</div>
						</div>
						<div class="operater">
							<div class="btn btn-cancel" @click="cancelAdd(1)">取消</div>
							<div class="btn btn-confirm" @click="confirmAdd(1)">完成</div>
						</div>
					</div>
					<div class="step-item" v-if="!desireJobEdit">
						<div class="resume-box-head">
							<h2 class="resume-box-title">期望職位</h2>
							<a href="javascript:;" class="add-step-item" @click="changeEditStatus(1)">添加</a>
						</div>
						<div class="resume-box-content" v-for="(data,i) in resumeData.desireJobs" :key="i">
							<div class="resume-box-edit">
								<a href="javascript:;" class="btn-edit" @click="editRow(1, i)"><span>編輯</span></a>
								<a href="javascript:;" class="btn-del" v-if="resumeData.desireJobs.length > 1" @click="deleteRow(1, i)"><span>刪除</span></a>
							</div>
							<div class="col">
								<div class="row">
									<div class="field-read-item">
										<span class="field-title">職位:</span>
										<span class="field-value" v-text="position(data.desireJob)"></span>
									</div>
									<span class="divider"></span>
									<div class="field-read-item">
										<span class="field-title">薪資要求:</span>
										<span class="field-value" v-text="data.desireSalary"></span>
									</div>
								</div>
                                <div class="row">
									<div class="field-read-item">
										<span class="field-title">求職類型:</span>
										<span class="field-value" v-text="getJobType(data.type)"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tabview" v-if="desireJobEdit">
						<div class="header">
							<h2 class="title" v-text="editTitle"></h2>
						</div>
						<div class="field-list">
							<div class="field-item full-line">
								<div class="field-label">
									求職類型<span class="required">*</span>
								</div>
								<div class="field-value">
									<select v-model="addInfo.desireJobs.type" placeholder="請選擇">
										<option value="" disabled selected>請選擇</option>
										<option value="1">全職</option>
										<option value="2">兼職</option>
										<option value="3">實習</option>
									</select>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									期望職位<span class="required">*</span>
								</div>
								<div class="field-value">
									<select v-model="addInfo.desireJobs.desireJob" placeholder="請選擇">
										<option value="" disabled selected>請選擇</option>
										<option value="1">註冊中醫師</option>
										<option value="2">表列中醫師</option>
										<option value="3">診所助理</option>
										<option value="4">配藥員</option>
										<option value="5">講師</option>
										<option value="6">推拿師</option>
										<option value="7">臨時講座講者</option>
										<option value="8">義診醫師</option>
										<option value="9">義診助手</option>
										<option value="10">外展醫師</option>
										<option value="11">學徒</option>
									</select>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									期望薪資<span class="required">*</span>
								</div>
								<div class="range-value">
									<div class="field-value">
										<select v-model="addInfo.salaryLeft" @change="onSalaryLeftChange($event)" placeholder="請選擇">
											<option value="" disabled selected>請選擇</option>
											<option v-for="(item,index) in salaryLeftList" :key="index" :value="item" v-text="item"></option>
										</select>
									</div>
									<div class="label" v-if="addInfo.salaryLeft != '面議'">至</div>
									<div class="field-value" v-if="addInfo.salaryLeft != '面議'">
										<select v-model="addInfo.salaryRight" placeholder="請選擇" @change="onSalaryRigthChange($event)">
											<option value="" disabled selected>請選擇</option>
											<option v-for="(item,index) in salaryRightList" :key="index" :value="item" v-text="item"></option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div class="operater">
							<div class="btn btn-cancel" @click="cancelAdd(2)">取消</div>
							<div class="btn btn-confirm" @click="confirmAdd(2)">完成</div>
						</div>
					</div>
					<div class="step-item" v-if="!workExperienceEdit">
						<div class="resume-box-head">
							<h2 class="resume-box-title">工作經驗</h2>
							<a href="javascript:;" class="add-step-item" @click="changeEditStatus(2)">添加</a>
						</div>
						<div class="resume-box-content" v-for="(data,i) in resumeData.workExperiences" :key="i">
							<div class="resume-box-edit">
								<a href="javascript:;" class="btn-edit" @click="editRow(2, i)"><span>編輯</span></a>
								<a href="javascript:;" class="btn-del" v-if="resumeData.workExperiences.length > 1" @click="deleteRow(2, i)"><span>刪除</span></a>
							</div>
							<div class="col">
								<div class="row">
									<div class="field-read-item">
										<span class="field-title h3" v-text="data.company"></span>
										<span class="field-value hint" v-text="`${data.startDate.substring(0, 7)} ~ ${data.endDate.substring(0, 7)}`"></span>
									</div>
								</div>
								<div class="row">
									<div class="field-read-item">
										<span class="field-title">職位:</span>
										<span class="field-value" v-text="`${position(data.position)}`"></span>
									</div>
									<span class="divider"></span>
									<div class="field-read-item">
										<span class="field-title">是否在職:</span>
										<span class="field-value" v-text="`${toPresent(data.toPresent)}`"></span>
									</div>
								</div>
								<div class="row">
									<div class="field-read-item">
										<span class="field-title">職位描述:</span>
										<span class="field-value" v-text="`${data.jobDuties || '--'}`"></span>
									</div>
								</div>
    							<div class="row">
    								<div class="field-read-item">
    									<span class="field-title">過往使用系統:</span>
                                        <div class="field-value" v-text="data.apps"></div>
    								</div>
    							</div>
							</div>
						</div>
					</div>
					<div class="tabview" v-if="workExperienceEdit">
						<div class="header">
							<h2 class="title" v-text="editTitle"></h2>
						</div>
						<div class="field-list">
							<div class="field-item full-line">
								<div class="field-label">
									公司名稱<span class="required">*</span>
								</div>
								<div class="field-value">
									<input type="text" v-model="addInfo.workExperiences.company" placeholder="請輸入公司名稱">
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									是否在職<span class="required">*</span>
								</div>
								<div class="field-value radio">
                                    <van-radio-group v-model="addInfo.workExperiences.toPresent">
                                        <van-radio name="1">是</van-radio>
                                        <van-radio name="">否</van-radio>
                                      </van-radio-group>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									職位<span class="required">*</span>
								</div>
								<div class="field-value">
									<select v-model="addInfo.workExperiences.position" placeholder="請選擇">
										<option value="" disabled selected>請選擇</option>
										<option value="1">註冊中醫師</option>
										<option value="2">表列中醫師</option>
										<option value="3">診所助理</option>
										<option value="4">配藥員</option>
										<option value="5">講師</option>
										<option value="6">推拿師</option>
										<option value="7">臨時講座講者</option>
										<option value="8">義診醫師</option>
										<option value="9">義診助手</option>
										<option value="10">外展醫師</option>
										<option value="11">學徒</option>
									</select>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									在職時間段<span class="required">*</span>
								</div>
								<div class="field-value">
                                    <div class="row">
                                        <div class="col" v-text="addInfo.workExperiences.startDate || '開始日期'" @click="onWorkDateRangeChange(1)"></div>
                                        <span class="divider">~</span>
                                        <div class="col" v-text="addInfo.workExperiences.endDate || '結束日期'" @click="onWorkDateRangeChange(2)"></div>
                                    </div>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									職位描述<span class="required">*</span>
								</div>
								<div class="field-value">
									<textarea type="text" cols="120" rows="4" v-model="addInfo.workExperiences.jobDuties" placeholder="請輸入工作描述"></textarea>
								</div>
							</div>
                            <div class="field-item full-line">
                                <div class="field-label">
                                    過往使用系統
                                </div>
                                <div class="field-value">
                                    <textarea cols="120" rows="4" v-model="addInfo.workExperiences.apps" placeholder="請輸入過往使用系統"></textarea>
                                </div>
                            </div>
						</div>
						<div class="operater">
							<div class="btn btn-cancel" @click="cancelAdd(3)">取消</div>
							<div class="btn btn-confirm" @click="confirmAdd(3)">完成</div>
						</div>
					</div>
					<div class="step-item" v-if="!educationEdit">
						<div class="resume-box-head">
							<h2 class="resume-box-title">學歷</h2>
							<a href="javascript:;" class="add-step-item" @click="changeEditStatus(3)">添加</a>
						</div>
						<div class="resume-box-content" v-for="(data,i) in resumeData.educations" :key="i">
							<div class="resume-box-edit">
								<a href="javascript:;" class="btn-edit" @click="editRow(3, i)"><span>編輯</span></a>
								<a href="javascript:;" class="btn-del" v-if="resumeData.educations.length > 1" @click="deleteRow(3, i)"><span>刪除</span></a>
							</div>
							<div class="col">
								<div class="row">
									<div class="field-read-item">
										<span class="field-title h3" v-text="data.institutionName"></span>
										<span class="field-value hint" v-text="`${data.startTime.substring(0, 4)} ~ ${data.endTime.substring(0, 4)}`"></span>
									</div>
								</div>
								<div class="row">
									<div class="field-read-item">
										<span class="field-title">專業:</span>
										<span class="field-value" v-text="data.profession"></span>
									</div>
									<span class="divider"></span>
									<div class="field-read-item">
										<span class="field-title">學歷:</span>
										<span class="field-value" v-text="highestEduLevel(data.education)"></span>
									</div>
								</div>
								<div class="row">
									<div class="field-read-item">
										<span class="field-title">資格證書:</span>
										<p class="field-value" v-text="data.credentials"></p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tabview" v-if="educationEdit">
						<div class="header">
							<h2 class="title" v-text="editTitle"></h2>
						</div>
						<div class="field-list">
							<div class="field-item">
								<div class="field-label">
									學校名稱<span class="required">*</span>
								</div>
								<div class="field-value">
									<input type="text" v-model="addInfo.educations.institutionName" placeholder="請輸入學校名稱">
								</div>
							</div>
							<div class="field-item">
								<div class="field-label">
									學歷<span class="required">*</span>
								</div>
								<div class="field-value">
									<select v-model="addInfo.educations.education" placeholder="請選擇">
										<option value="" disabled selected>請選擇</option>
										<option value="1">初中及以下</option>
										<option value="2">中專/中技</option>
										<option value="3">高中</option>
										<option value="4">大專</option>
										<option value="5">本科</option>
										<option value="6">碩士</option>
										<option value="7">博士</option>
									</select>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									專業<span class="required">*</span>
								</div>
								<div class="field-value">
									<input type="text" v-model="addInfo.educations.profession" placeholder="請輸入專業">
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									時間段<span class="required">*</span>
								</div>
								<div class="field-value">
                                    <div class="row">
                                        <div class="col" v-text="addInfo.educations.startTime || '開始日期'" @click="onEducationDateRangeChange(1)"></div>
                                        <span class="divider">~</span>
                                        <div class="col" v-text="addInfo.educations.endTime || '結束日期'" @click="onEducationDateRangeChange(2)"></div>
                                    </div>
								</div>
							</div>
							<div class="field-item full-line">
								<div class="field-label">
									資格證書
								</div>
								<div class="field-value">
									<textarea type="text" cols="120" rows="4" v-model="addInfo.educations.credentials" placeholder="請輸入工作描述"></textarea>
								</div>
                                <div class="btn-add-credentials-file" @click="uploadCredentialsFile">添加資格證書附件</div>
							</div>
						</div>
						<div class="operater">
							<div class="btn btn-cancel" @click="cancelAdd(4)">取消</div>
							<div class="btn btn-confirm" @click="confirmAdd(4)">完成</div>
						</div>
					</div>
				</div>
			</div>
			<div class="empty-resume" v-if="hasResume == false">
				<p class="hint">您還未添加履歷~</p>
				<div class="btn-add-resume" @click="addResume">添加履歷</div>
			</div>
		</div>
        <form ref="form" style="display: none;">
            <input type="file" ref="file" id="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" @change="onFileChanged">
            <input type="file" ref="credentialsFile" id="credentialsFile" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" @change="onCredentialsFileChanged"/>
        </form>
        <van-popup
            v-model="show"
            position="bottom"
            :style="{ height: '30%' }"
        >
            <van-datetime-picker
                v-model="currentDate"
                type="year-month"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @cancel="show=false"
                @confirm="onDateTimePickConfirm"
            />
        </van-popup>
        <loading v-if="loading"/>
        <div v-if="isShowAddResumeDialog" class="dialog-add-resume">
            <add-resume :onClose="()=>isShowAddResumeDialog=false"/>
        </div>
        <confirm-dialog :message="dialogMessage" v-if="showConfirmDialog" :onDialogClose="onDialogClose" />
		<input-dialog :inputValue="inputDialogValue" title="請輸入附件名稱" v-if="showInputDialog" :onInputDialogClose="onInputDialogClose" />
    </div>
</template>

<script>
import Loading from '@/layouts/Loading.vue'
import AddResume from './AddResume.vue'
import ConfirmDialog from '@/layouts/ConfirmDialog.vue'
import InputDialog from '@/layouts/InputDialog.vue'
import moment from 'moment'
export default {
    components: {
        Loading,
        AddResume,
        ConfirmDialog,
		InputDialog,
    },
    mounted: function () {
        this.getResume();
        this.addInfo.workYearList.length = 0
        for(let i=0; i<=60; i++){
            this.addInfo.workYearList.push(i)
        }
    },
    computed: {
        yearOfWorkExp: function() {
            var text = this.resumeData.resume.yearOfWorkExp
            return `${text}年`
        },
        mobile: function() {
            var phone = this.resumeData.resume.mobile
            return phone.substring(0, 3) + '****' + phone.substring(7, 11);
        },
        selectedWorkDate() {
            if(!this.addInfo.workExperiences.startDate) {
                return '請選擇'
            }
            return `${this.addInfo.workExperiences.startDate} ~ ${this.addInfo.workExperiences.endDate}`
        },
        selectedEducDate() {
            console.log('selectedEducDate')
            if(!this.addInfo.educations.startTime) {
                return '請選擇'
            }
            return `${this.addInfo.educations.startTime} ~ ${this.addInfo.educations.endTime}`
        }
    },
    data() {
        return {
            show: false,
            rangeDateTag: -1,
            dateRangeType: undefined,
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            
			dialogMessage: '',
            showConfirmDialog: false,
            onDialogConfirm: undefined,
			showInputDialog: false,
			inputDialogValue: '',
            onInputDialogResult: undefined,
            editIndex: -1,
            minDate: new Date(1900, 0, 1),
            editTitle: '',//添加工作經驗 添加學歷 編輯個人信息
            salaryLeftList: ['面議', '1k', '2k', '3k', '4k', '5k', '6k', '7k', '8k', '9k', '10k', '11k', '12k', '13k', '14k', '15k', '16k', '17k', '18k', '19k', '20k', '21k', '22k', '23k', '24k', '25k', '26k', '27k', '28k', '29k', '30k', '35k', '40k', '45k', '50k', '55k', '60k', '65k', '70k', '75k', '80k', '85k', '90k', '95k', '100k'],
            salaryRightList: [],
            addInfo: {
                workYearList: [],
                salaryLeft: '',
                salaryRight: '',
                workExperienceDate: ['',''],
                educationDate: ['',''],
                basicInfo: {
                    yearOfWorkExp: '',
                    highestEduLevel: '',
                    email: this.$store.getters.userInfo && this.$store.getters.userInfo.clientemail,
                    mobile: this.$store.getters.userInfo && this.$store.getters.userInfo.clientmobile,
                },
                desireJobs: {
                    desireJob: '',
                    desireSalary: undefined,
                    type: '',
                },
                workExperiences: {
                    company: undefined, //公司名称
                    position: '', //职位
                    toPresent: undefined, //当前是否还在职
                    jobDuties: undefined, //职位描述
                    startDate: '', //开始时间
                    endDate: '', //结束时间 
                },
                educations:  {
                    credentials: undefined, //资格证书
                    education: '', //学历
                    institutionName: undefined, //机构(学校)名称
                    profession: undefined, //专业
                    startTime: '', //开始时间
                    endTime: '', //结束时间
                },
            },
            style: '',
            loading: true,
            basicInfoEdit: false,
            educationEdit: false,
            workExperienceEdit: false,
            desireJobEdit: false,
            userInfo: this.$store.getters.userInfo,
            isShowAddResumeDialog: false,
            hasResume: undefined,
			privacyOn: false,
            jobTypeList: [
                {name:'全職',value:'1'},
                {name:'兼職',value:'2'},
                {name:'實習', value:'3'},
            ],
            positionList: [
                {name:'註冊中醫師',value:'1'},
                {name:'表列中醫師',value:'2'},
                {name:'診所助理',value:'3'},
                {name:'配藥員',value:'4'},
                {name:'講師',value:'5'},
                {name:'推拿師',value:'6'},
                {name:'臨時講座講者',value:'7'},
                {name:'義診醫師',value:'8'},
                {name:'義診助手',value:'9'},
                {name:'外展醫師', value:'10'},
                {name:'學徒', value:'11'},
            ],
            highestEduLevelList: [
                {name: '初中及以下', value: '1'},
                {name: '中专/中技', value: '2'},
                {name: '高中', value: '3'},
                {name: '大专', value: '4'},
                {name: '本科', value: '5'},
                {name: '硕士', value: '6'},
                {name: '博士', value: '7'},
            ],
            resumeData: undefined,
        };
    },
    methods: {
        moment,
        resetAddInfo() {
            this.editIndex = -1
            this.addInfo = {
                workYearList: [],
                salaryLeft: '',
                salaryRight: '',
                workExperienceDate: ['',''],
                educationDate: ['',''],
                basicInfo: {
                    yearOfWorkExp: '',
                    highestEduLevel: '',
                    email: this.$store.getters.userInfo && this.$store.getters.userInfo.clientemail,
                    mobile: this.$store.getters.userInfo && this.$store.getters.userInfo.clientmobile,
                },
                desireJobs: {
                    desireJob: '',
                    desireSalary: undefined,
                    type: '',
                },
                workExperiences: {
                    company: undefined, //公司名称
                    position: '', //职位
                    toPresent: undefined, //当前是否还在职
                    jobDuties: undefined, //职位描述
                    startDate: '', //开始时间
                    endDate: '', //结束时间 
                },
                educations:  {
                    credentials: undefined, //资格证书
                    education: '', //学历
                    institutionName: undefined, //机构(学校)名称
                    profession: undefined, //专业
                    startTime: '', //开始时间
                    endTime: '', //结束时间
                },
            }
            
            this.addInfo.workYearList.length = 0
            for(let i=0; i<=60; i++){
                this.addInfo.workYearList.push(i)
            }
        },
        getJobType(v){
            var text = v || ''
            var model = this.jobTypeList.find((v)=>v.value == text)
            if(model){
                return model.name
            }
            return v
        },
        highestEduLevel(v) {
            var text = v || ''
            var model = this.highestEduLevelList.find((v)=>v.value == text)
            if(model){
                return model.name
            }
            return v
        },
        position(v) {
            var text = v || ''
            var model = this.positionList.find((v)=>v.value == text)
            if(model){
                return model.name
            }
            return ''
        },
        toPresent(v) {
            if(v == true){
                return '是'
            }
            return '否'
        },
        getResume() {
            const params = {
                "clientId": this.$store.getters.clientId,
                "uid": this.$store.getters.clientId,
            }
            this.$store.dispatch('getResume', params).then((resume)=>{
                this.hasResume = resume && resume.exist;
                this.loading = false
                this.resumeData = resume;
				this.privacyOn = this.hasResume && resume.resume && resume.resume.privacyStatus == 1
            });
        },
        addResume() {
            this.isShowAddResumeDialog = !this.isShowAddResumeDialog
            if(this.isShowAddResumeDialog) {
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
            }
        },
        onClose(){
            document.body.setAttribute('style', this.style) 
        },
        deleteResume(){
            this.showConfirmDialog = true
            this.onDialogConfirm = ()=>{
                this.loading = true
                const params = {
                    "clientId": this.$store.getters.clientId,
                    "uid": this.$store.getters.clientId,
                }
                this.$store.dispatch('deleteResume', params)
                    .then((rs) => {
                        this._toast.success(this, '刪除履歷成功！')
                        this.educationEdit = false
                        this.workExperienceEdit = false
                        this.desireJobEdit = false
                        this.getResume();
                    }).catch((error) => {
                        this.loading = false
                        this._toast.warning(this, error || '操作失敗')
                    })
            }
        },
        onSalaryLeftChange(e) {
            var value = e.target.value;
            if(value == '面議') {
                this.addInfo.desireJobs.desireSalary = '面議'
                return
            }
            this.addInfo.desireJobs.desireSalary = undefined;
            const start = this.salaryLeftList.indexOf(value)
            var end = start+6;
            if(end >= this.salaryLeftList.length) {
                end = this.salaryLeftList.length-1;
            }
            this.salaryRightList.length = 0;
            for(var i=start+1; i<=end; i++) {
                this.salaryRightList.push(this.salaryLeftList[i])
            }
            if(!this.salaryRightList.length) {
                this.salaryRightList.push(this.salaryLeftList[start])
            }
        },
        onSalaryRigthChange(e) {
            var value = e.target.value;
            if(value) {
                this.addInfo.desireJobs.desireSalary = `${this.addInfo.salaryLeft}~${this.addInfo.salaryRight}`;
            }
        },
        onWorkDateRangeChange(v) {
            this.show = true
            this.dateRangeType = 'workExperiences'
            this.rangeDateTag = v
            if(v == 1) {
                //开始
                if(this.addInfo.workExperiences.startDate) {
                    this.currentDate = new Date(this.addInfo.workExperiences.startDate)
                }
                if(this.addInfo.workExperiences.endDate) {
                    this.maxDate = new Date(this.addInfo.workExperiences.endDate)
                    this.minDate = new Date(1900, 0, 1)
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            } else if(v == 2) {
                //结束
                if(this.addInfo.workExperiences.endDate) {
                    this.currentDate = new Date(this.addInfo.workExperiences.endDate)
                }
                if(this.addInfo.workExperiences.startDate) {
                    this.minDate = new Date(this.addInfo.workExperiences.startDate)
                    this.maxDate = new Date()
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            }
            
        },
        onEducationDateRangeChange(v) {
            this.show = true
            this.dateRangeType = 'educations'
            this.rangeDateTag = v
            if(v == 1) {
                //开始
                if(this.addInfo.educations.startTime) {
                    this.currentDate = new Date(this.addInfo.educations.startTime)
                }
                if(this.addInfo.educations.endTime) {
                    this.maxDate = new Date(this.addInfo.educations.endTime)
                    this.minDate = new Date(1900, 0, 1)
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            } else if(v == 2) {
                //结束
                if(this.addInfo.educations.endTime) {
                    this.currentDate = new Date(this.addInfo.educations.endTime)
                }
                if(this.addInfo.educations.startTime) {
                    this.minDate = new Date(this.addInfo.educations.startTime)
                    this.maxDate = new Date()
                } else {
                    this.minDate = new Date(1900, 0, 1)
                    this.maxDate = new Date()
                }
            }
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }
            return val;
        },
        onDateTimePickConfirm(d){
            this.show = false
            if(this.dateRangeType == 'educations') {
                if(this.rangeDateTag == 1) {
                    this.addInfo.educations.startTime = this.moment(d).format("YYYY-MM")
                } else if(this.rangeDateTag == 2) {
                    this.addInfo.educations.endTime = this.moment(d).format("YYYY-MM")
                }
            }  else if(this.dateRangeType == 'workExperiences') {
                if(this.rangeDateTag == 1) {
                    this.addInfo.workExperiences.startDate = this.moment(d).format("YYYY-MM")
                } else if(this.rangeDateTag == 2) {
                    this.addInfo.workExperiences.endDate = this.moment(d).format("YYYY-MM")
                }
            }
            this.dateRangeType = undefined
            this.rangeDateTag = -1

        },
        updateBasicInfo() {
            this.basicInfoEdit = !this.basicInfoEdit
            this.editTitle = '編輯個人信息'
            this.desireJobEdit = false
            this.workExperienceEdit = false
            this.educationEdit = false

            this.addInfo.basicInfo = {
                doctorNo: this.resumeData.resume.doctorNo,
                yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                highestEduLevel: this.resumeData.resume.highestEduLevel,
                email: this.resumeData.resume.email,
                mobile: this.resumeData.resume.mobile,
            }
        },
        changeEditStatus(v) {
            if(v == 1) {
                this.basicInfoEdit = false
                this.desireJobEdit = !this.desireJobEdit
                this.workExperienceEdit = false
                this.educationEdit = false
                this.editTitle = '添加期望職位'
            } else if(v == 2) {
                this.basicInfoEdit = false
                this.desireJobEdit = false
                this.workExperienceEdit = !this.workExperienceEdit
                this.educationEdit = false
                this.editTitle = '添加工作經驗'
            } else if(v == 3) {
                this.basicInfoEdit = false
                this.desireJobEdit = false
                this.workExperienceEdit = false
                this.educationEdit = !this.educationEdit
                this.editTitle = '添加學歷'
            }
        },
        cancelAdd(v) {
            this.resetAddInfo()
            if(v == 1) {
                this.basicInfoEdit = false
            } else if(v == 2) {
                this.desireJobEdit = false
            } else if(v == 3) {
                this.workExperienceEdit = false
            } else if(v == 4) {
                this.educationEdit = false 
            }
        },
        confirmAdd(v) {
            var params = undefined
            if(v == 1) {
                if(!(this.addInfo.basicInfo.email||'').trim()) {
                    this._toast.warning(this, '請輸入郵箱')
                    return
                }
                if(!(this.addInfo.basicInfo.mobile||'').trim()) {
                    this._toast.warning(this, '請輸入電話')
                    return
                }
                if(!this.addInfo.basicInfo.highestEduLevel) {
                    this._toast.warning(this, '請選擇學歷')
                    return
                }
                if(!(this.addInfo.basicInfo.yearOfWorkExp >= 0)) {
                    this._toast.warning(this, '請選擇相關工作經驗')
                    return
                }
                params = {
                    title: this.resumeData.resume.title,
                    uid: this.$store.getters.clientId,
                    email: this.resumeData.resume.email,
                    mobile: this.resumeData.resume.mobile,
                    highestEduLevel: this.resumeData.resume.highestEduLevel,
                    yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                    desireJobs: this.resumeData.desireJobs,
                    educations: this.resumeData.educations,
                    workExperiences: this.resumeData.workExperiences,
                    ...this.addInfo.basicInfo,
                }
            } else if(v == 2) {
                if(!this.addInfo.desireJobs.type) {
                    this._toast.warning(this, '請選擇求職類型')
                    return
                }
                if(!this.addInfo.desireJobs.desireJob) {
                    this._toast.warning(this, '請選擇期望職位')
                    return
                }
                var text = this.addInfo.desireJobs.desireJob
                var list = [...this.resumeData.desireJobs]
                list.splice[this.editIndex, 1];
                if(list.filter((e)=>e.desireJob==text).length > 0) {
                    this._toast.warning(this, '期望職位不能重複')
                    return
                }
                if(!this.addInfo.desireJobs.desireSalary) {
                    this._toast.warning(this, '請選擇期望薪資')
                    return
                }
                params = {
                    title: this.resumeData.resume.title,
                    uid: this.$store.getters.clientId,
                    email: this.resumeData.resume.email,
                    mobile: this.resumeData.resume.mobile,
                    highestEduLevel: this.resumeData.resume.highestEduLevel,
                    yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                    educations: this.resumeData.educations,
                    workExperiences: this.resumeData.workExperiences,
                }
                if(this.editIndex != -1) {
                    var list = [...this.resumeData.desireJobs]
                    params.desireJobs = list.map((v, i)=>{
                        if(this.editIndex == i) {
                            return {
                                ...v,
                                ...this.addInfo.desireJobs,
                            }
                        }
                        return v
                    });
                } else {
                    params.desireJobs = [
                        ...this.resumeData.desireJobs,
                        this.addInfo.desireJobs,
                    ]
                }
            } else if(v == 3) {
                if(!(this.addInfo.workExperiences.company || '').trim()){
                    this._toast.warning(this, '請輸入公司名稱')
                    return
                }
                if(this.addInfo.workExperiences.toPresent == undefined){
                    this._toast.warning(this, '請選擇是否在職')
                    return
                }
                if(!this.addInfo.workExperiences.position){
                    this._toast.warning(this, '請選擇職位')
                    return
                }
                if(!this.addInfo.workExperiences.startDate || !this.addInfo.workExperiences.endDate){
                    this._toast.warning(this, '請選擇在職時間段')
                    return
                }
                if(!this.addInfo.workExperiences.jobDuties){
                    this._toast.warning(this, '請輸入職位描述')
                    return
                }
                params = {
                    title: this.resumeData.resume.title,
                    uid: this.$store.getters.clientId,
                    email: this.resumeData.resume.email,
                    mobile: this.resumeData.resume.mobile,
                    highestEduLevel: this.resumeData.resume.highestEduLevel,
                    yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                    desireJobs: this.resumeData.desireJobs,
                    educations: this.resumeData.educations,
                }
                if(this.editIndex != -1) {
                    var list = [...this.resumeData.workExperiences]
                    params.workExperiences = list.map((v, i)=>{
                        if(this.editIndex == i) {
                            return {
                                ...v,
                                ...this.addInfo.workExperiences,
                                startDate: `${this.addInfo.workExperiences.startDate}-01`, //开始时间
                                endDate: `${this.addInfo.workExperiences.endDate}-01`, //结束时间 
                                toPresent: !!this.addInfo.workExperiences.toPresent
                            }
                        }
                        return v
                    });
                } else {
                    params.workExperiences = [
                        ...this.resumeData.workExperiences,
                        {
                            ...this.addInfo.workExperiences,
                            startDate: `${this.addInfo.workExperiences.startDate}-01`, //开始时间
                            endDate: `${this.addInfo.workExperiences.endDate}-01`, //结束时间 
                            toPresent: !!this.addInfo.workExperiences.toPresent
                        },
                    ]
                }
            } else if(v == 4) {
                if(!(this.addInfo.educations.institutionName || '').trim()){
                    this._toast.warning(this, '請輸入學校名稱')
                    return
                }
                if(!this.addInfo.educations.education){
                    this._toast.warning(this, '請選擇學歷')
                    return
                }
                if(!(this.addInfo.educations.profession || '').trim()){
                    this._toast.warning(this, '請輸入專業')
                    return
                }
                if(!this.addInfo.educations.startTime || !this.addInfo.educations.endTime){
                    this._toast.warning(this, '請選擇在校時間段')
                    return
                }
                params = {
                    title: this.resumeData.resume.title,
                    uid: this.$store.getters.clientId,
                    email: this.resumeData.resume.email,
                    mobile: this.resumeData.resume.mobile,
                    highestEduLevel: this.resumeData.resume.highestEduLevel,
                    yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                    desireJobs: this.resumeData.desireJobs,
                    workExperiences: this.resumeData.workExperiences
                }
                if(this.editIndex != -1) {
                    var list = [...this.resumeData.educations]
                    params.educations = list.map((v,i)=>{
                        if(this.editIndex == i) {
                            return {
                                ...v,
                                ...this.addInfo.educations,
                                startTime: `${this.addInfo.educations.startTime}-01`, //开始时间
                                endTime: `${this.addInfo.educations.endTime}-01`, //结束时间 
                            }
                        }
                        return v
                    });
                } else {
                    params.educations = [
                        ...this.resumeData.educations,
                        {
                            ...this.addInfo.educations,
                            startTime: `${this.addInfo.educations.startTime}-01`, //开始时间
                            endTime: `${this.addInfo.educations.endTime}-01`, //结束时间 
                        }
                        
                    ]
                }
            }

            if(params) {
                this.loading = true
				params.privacyStatus = this.privacyOn == true ? 1 : 0
                this.$store.dispatch('updateResume', params).then((resume)=>{
                    this._toast.success(this, '操作成功')
                    this.basicInfoEdit = false
                    this.educationEdit = false
                    this.workExperienceEdit = false
                    this.desireJobEdit = false
                    this.resetAddInfo();
                    this.getResume();
                });
            }
        },
        editRow(v, index) {
            if(v == 1) {
                this.basicInfoEdit = false
                this.desireJobEdit = !this.desireJobEdit
                this.workExperienceEdit = false
                this.educationEdit = false
                this.editTitle = '編輯期望職位'
                console.log('index',index)
                this.editIndex = index
                var desireJobs = this.resumeData.desireJobs[index];
                if(desireJobs.desireSalary) {
                    var list = desireJobs.desireSalary.split('~')
                    if(list.length == 2) {
                        this.addInfo.salaryLeft = list[0]
                        const start = this.salaryLeftList.indexOf(this.addInfo.salaryLeft)
                        var end = start+6;
                        if(end >= this.salaryLeftList.length) {
                            end = this.salaryLeftList.length-1;
                        }
                        this.salaryRightList.length = 0;
                        for(var i=start+1; i<=end; i++) {
                            this.salaryRightList.push(this.salaryLeftList[i])
                        }
                        this.addInfo.salaryRight = list[1]
                    } else {
                        this.addInfo.salaryLeft = desireJobs.desireSalary
                    }
                }
                this.addInfo.desireJobs = {
                    ...desireJobs
                }
            } else if(v == 2) {
                this.basicInfoEdit = false
                this.desireJobEdit = false
                this.workExperienceEdit = !this.workExperienceEdit
                this.educationEdit = false
                this.editTitle = '編輯工作經驗'
                this.editIndex = index
                var workExperiences = this.resumeData.workExperiences[index]
                this.addInfo.workExperienceDate = [moment(workExperiences.startDate, 'YYYY-MM'), moment(workExperiences.endDate, 'YYYY-MM')]
                this.addInfo.workExperiences = {
                    ...workExperiences,
                    startDate: this.moment(new Date(workExperiences.startDate)).format("YYYY-MM"), //开始时间
                    endDate: this.moment(new Date(workExperiences.endDate)).format("YYYY-MM"), //结束时间
                    toPresent: workExperiences.toPresent == true ? '1': ''
                }
            } else if(v == 3) {
                this.basicInfoEdit = false
                this.desireJobEdit = false
                this.workExperienceEdit = false
                this.educationEdit = !this.educationEdit
                this.editTitle = '編輯學歷'
                this.editIndex = index
                var educations = this.resumeData.educations[index]
                console.log('educations',educations)
                this.addInfo.educationDate = [moment(educations.startTime, 'YYYY-MM'), moment(educations.endTime, 'YYYY-MM')]
                this.addInfo.educations = {
                    ...educations,
                    startTime: this.moment(new Date(educations.startTime)).format("YYYY-MM"), //开始时间
                    endTime: this.moment(new Date(educations.endTime)).format("YYYY-MM"), //结束时间
                }
            }
        },
        deleteRow(v, index) {
            this.showConfirmDialog = true
            this.onDialogConfirm = ()=>{
                var desireJobs = [...this.resumeData.desireJobs]
                var workExperiences = [...this.resumeData.workExperiences]
                var educations = [...this.resumeData.educations]
                if(v == 1) {
                    desireJobs.splice(index, 1)
                } else if(v == 2) {
                    workExperiences.splice(index, 1)
                } else if(v == 3) {
                    educations.splice(index, 1)
                }
                this.loading = true
                var params = {
                    title: this.resumeData.resume.title,
                    uid: this.$store.getters.clientId,
                    email: this.resumeData.resume.email,
                    mobile: this.resumeData.resume.mobile,
                    highestEduLevel: this.resumeData.resume.highestEduLevel,
                    yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                    desireJobs,
                    educations,
                    workExperiences,
					privacyStatus: this.privacyOn == true ? 1 : 0,
                }
                this.$store.dispatch('updateResume', params).then((resume)=>{
                    this._toast.success(this, '操作成功')
                    this.basicInfoEdit = false
                    this.educationEdit = false
                    this.workExperienceEdit = false
                    this.desireJobEdit = false
                    this.resetAddInfo();
                    this.getResume();
                });
            }
        },
        onDialogClose(flag) {
            this.showConfirmDialog = false
            if(flag == true) {
                this.onDialogConfirm && this.onDialogConfirm()
            }
        },
		onInputDialogClose(flag, v) {
			this.showInputDialog = false
            if(flag == true) {
                this.onInputDialogResult && this.onInputDialogResult(v)
            }
		},
		changeResumeStatus() {
			var resultMethod = ()=>{
				this.loading = true
				const params = {
					"clientId": this.$store.getters.clientId,
					"uid": this.$store.getters.clientId,
					"open": !this.privacyOn
				}
				this.$store.dispatch('changeResumeStatus', params).then((resume)=>{
					this.privacyOn = !this.privacyOn
				}).finally(()=> {
					this.loading = false
					this.dialogMessage = ''
				})
			}
			if(this.privacyOn == true) {
				this.dialogMessage = '確定關閉履歷開放狀態嘛？'
				this.showConfirmDialog = true
				this.onDialogConfirm = resultMethod
			} else {
				resultMethod()
			}
		},
		uploadFile(v){
			if(this.loading) return
			this.$refs.file.click && this.$refs.file.click()
		},
        uploadCredentialsFile(v){
			if(this.loading) return
			this.$refs.credentialsFile.click && this.$refs.credentialsFile.click()
		},
        onCredentialsFileChanged(v){
			if(!v) return
			if(this.loading) return
			var files = this.$refs.credentialsFile.files
			if(!files || !files.length) return
			this.loading = true
			var formData = new FormData()
			formData.append("files", files[0]);
			this.$store.dispatch('uploadFile', formData)
			.then((rs)=>{
				var url = rs[0]
                if(this.addInfo.educations.credentials) {
                    this.addInfo.educations.credentials = `${this.addInfo.educations.credentials}\n${url}`
                } else {
                    this.addInfo.educations.credentials = url
                }
			}).catch(error => {
				this._toast.warning(this, error || '上傳失敗')
			}).finally(()=>{
				this.$refs.credentialsFile.value = ""
				this.loading = false
			});
		},
		onFileChanged(v){
			if(!v) return
			if(this.loading) return
			var files = this.$refs.file.files
			if(!files || !files.length) return
			this.loading = true
			var formData = new FormData()
			formData.append("files", files[0]);
			this.$store.dispatch('uploadFile', formData)
			.then((rs)=>{
				var url = rs[0]
				var file = url.substring(url.lastIndexOf('/')+1)
				var title = file.substring(0, file.lastIndexOf('.'))
				var params = {
					title: this.resumeData.resume.title,
					uid: this.$store.getters.clientId,
					email: this.resumeData.resume.email,
					mobile: this.resumeData.resume.mobile,
					highestEduLevel: this.resumeData.resume.highestEduLevel,
					yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
					desireJobs: this.resumeData.desireJobs,
					educations: this.resumeData.educations,
					workExperiences: this.resumeData.workExperiences,
					privacyStatus: this.privacyOn == true ? 1 : 0,
					attachments: [
						...this.resumeData.attachments,
						{
							title,
							url,
						}
						
					]
				}
				this.$store.dispatch('updateResume', params).then((resume)=>{
					this._toast.success(this, '操作成功')
					this.basicInfoEdit = false
					this.educationEdit = false
					this.workExperienceEdit = false
					this.desireJobEdit = false
					this.resetAddInfo();
					this.getResume();
				}).finally(()=>{
					this.$refs.file.value = ""
					this.loading = false
				});
			}).catch(error => {
				this._toast.warning(this, error || '上傳失敗')
			}).finally(()=>{
				this.$refs.file.value = ""
				this.loading = false
			});
		},
		downloadResumeFile(i) {
			var url = this.resumeData.attachments[i].url
			fetch(url).then(response => response.blob()).then(blob => {
				// Create a temporary anchor element
				const a = document.createElement('a');
				a.style.display = 'none';
				document.body.appendChild(a);

				// Create the URL object for the blob
				const objectUrl = URL.createObjectURL(blob);

				// Set the anchor's href to the URL
				a.href = objectUrl;

				// Specify the file name (optional)
				a.download = 'filename.extension';

				// Trigger the download by simulating a click
				a.click();

				// Clean up resources
				URL.revokeObjectURL(objectUrl);
				document.body.removeChild(a);
			})
			.catch(error => {
				console.error('Error:', error);
			});
		},
		renameResumeFile(i) {
			this.showInputDialog = true
			this.inputDialogValue = this.resumeData.attachments[i].title
            this.onInputDialogResult = (v)=>{
				if(!v) return
				if(!v.trim()) return
                this.loading = true
				var attachments = [
					...this.resumeData.attachments
				]
				attachments[i].title = v
                var params = {
					title: this.resumeData.resume.title,
                    uid: this.$store.getters.clientId,
                    email: this.resumeData.resume.email,
                    mobile: this.resumeData.resume.mobile,
                    highestEduLevel: this.resumeData.resume.highestEduLevel,
                    yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                    desireJobs: this.resumeData.desireJobs,
                    educations: this.resumeData.educations,
                    workExperiences: this.resumeData.workExperiences,
					privacyStatus: this.privacyOn == true ? 1 : 0,
					attachments,
                }
                this.$store.dispatch('updateResume', params).then((resume)=>{
                    this._toast.success(this, '操作成功')
                    this.basicInfoEdit = false
                    this.educationEdit = false
                    this.workExperienceEdit = false
                    this.desireJobEdit = false
                    this.resetAddInfo();
                    this.getResume();
                }).finally(()=>this.loading = false);
            }
		},
		deleteResumeFile(i) {
			this.showConfirmDialog = true
            this.onDialogConfirm = ()=>{
                this.loading = true
				var attachments = [
					...this.resumeData.attachments
				]
				attachments.splice(i, 1)
                var params = {
					title: this.resumeData.resume.title,
                    uid: this.$store.getters.clientId,
                    email: this.resumeData.resume.email,
                    mobile: this.resumeData.resume.mobile,
                    highestEduLevel: this.resumeData.resume.highestEduLevel,
                    yearOfWorkExp: this.resumeData.resume.yearOfWorkExp,
                    desireJobs: this.resumeData.desireJobs,
                    educations: this.resumeData.educations,
                    workExperiences: this.resumeData.workExperiences,
					privacyStatus: this.privacyOn == true ? 1 : 0,
					attachments,
                }
                console.log('params',params)
                this.$store.dispatch('updateResume', params).then((resume)=>{
                    this._toast.success(this, '操作成功')
                    this.basicInfoEdit = false
                    this.educationEdit = false
                    this.workExperienceEdit = false
                    this.desireJobEdit = false
                    this.resetAddInfo();
                    this.getResume();
                }).finally(()=>this.loading = false);
            }
		}
    }
};
</script>

<style lang="scss">
    #my-resume {
        line-height: 1.5;
        color: #414a60;
        position: relative;
        padding-bottom: 24px;
    }

	#my-resume .edit-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 12px;
	}

	#my-resume .edit-menu .edit-menu-box {
		position: relative;
		background: #fff;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
        margin: 12px;
        width: 50%;
	}

	#my-resume .edit-menu .edit-menu-box::after {
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		width: 100%;
		height: 92px;
		background: linear-gradient(90deg,#f5fcfc,#fcfbfa);
		border-radius: 12px 12px 0 0;
	}

	#my-resume .edit-menu .edit-menu-box .menu-head {
		position: relative;
		z-index: 1;
		padding: 13px 20px 20px;
	}

	#my-resume .edit-menu .edit-menu-box .menu-head .menu-title {
		height: 80px;
		font-size: 32px;
		font-weight: 500;
		color: #222;
		line-height: 80px;
		align-items: center;
		display: flex;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content {
		min-height: 120px;
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		z-index: 1;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .btn-add-resume-file {
		height: 60px;
		line-height: 60px;
		background-color: #00bebd;
		border-color: #00bebd;
		border-radius: 8px;
		margin: 0px 36px;
		text-align: center;
		color: #fff;
		cursor: pointer;
		font-size: 28px;
		margin-bottom: 24px;
		margin-top: 10px;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .btn-add-resume-file:hover {
		opacity: 0.7;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .menu-item {
		height: 120px;
		padding: 0px 24px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .menu-item .title {
		color: #333;
		font-size: 30px;
		font-weight: 400;
		height: 120px;
		line-height: 120px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	#my-resume .edit-menu .edit-menu-box .menu-content .menu-item .switch {
		display: inline-block;
		width: 64px;
		height: 64px;
		margin-top: 4px;
		background-image: url(../../../../assets/imgs/pc/jobs/switch-off.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		cursor: pointer;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .menu-item .switch.on {
		background-image: url(../../../../assets/imgs/pc/jobs/switch-on.png);
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .menu-file {
		border-bottom: 1px solid #f3f3f3;
		margin: 0px 16px;
		padding: 8px;
		border-radius: 8px;
		cursor: pointer;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .menu-file .title {
		flex: 1;
		text-decoration: underline;
	}

	#my-resume .edit-menu .edit-menu-box .menu-content .menu-file .btn-resume-file {
		display: inline-block;
		width: 36px;
		height: 36px;
		margin-top: 8px;
		margin-left: 8px;
		background-image: url(../../../../assets/imgs/pc/jobs/icon_del_file.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		cursor: pointer;
	}
	#my-resume .edit-menu .edit-menu-box .menu-content .menu-file .btn-resume-file:hover {
		background-image: url(../../../../assets/imgs/pc/jobs/icon_del_file_selected.png);
	}
	#my-resume .edit-menu .edit-menu-box .menu-content .menu-file .btn-resume-file.update {
		margin-right: 16px;
		background-image: url(../../../../assets/imgs/pc/jobs/icon_edit_file.png);
	}
	#my-resume .edit-menu .edit-menu-box .menu-content .menu-file .btn-resume-file.update:hover {
		background-image: url(../../../../assets/imgs/pc/jobs/icon_edit_file_selected.png);
	}
	#my-resume .edit-menu .edit-menu-box .menu-content .menu-file:hover {
		background-color: #f3f3f3;
	}

	#my-resume .content {
		background-color: #fff;
		display: flex;
		flex-direction: column;
        margin: 20px 24px 0 24px;
	}

    #my-resume .resume-box-header {
        display: flex;
        flex-direction: row;
        height: 104px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f3f3f3;
        padding: 0px 32px;
    }

    #my-resume .resume-box-header > h2 {
        color: #414a60;
    }

    #my-resume .resume-box-header .header-right a{
        color: #00c2b3;
    }

    #my-resume .resume-box-header .header-right .link-preview-resume {
        margin-right: 16px;
        padding-right: 16px;
        position: relative;
    }

    #my-resume .empty-resume {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        align-items: center;
        justify-content: center;
    }

    #my-resume .empty-resume .hint {
        font-size: 32px;
        line-height: 40px;
        color: #999;
        user-select: none;
    }

    #my-resume .empty-resume .btn-add-resume {
        margin-top: 40px;
        border-radius: 8px;
        background-color: #00c2b3;
        height: 66px;
        line-height: 66px;
        padding: 0px 24px;
        text-align: center;
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        box-shadow: 2px 2px 5px #ccc;
    }

    #my-resume .empty-resume .btn-add-resume:active {
        box-shadow: none;
    }

    #my-resume .dialog-add-resume {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }

    #my-resume .resume-content {
        display: flex;
        flex-direction: column;
        font-size: 28px;
    }

    #my-resume .resume-box:hover {
        position: relative;
        background: #fff;
        box-shadow: 0 2px 14px 0 rgba(0,0,0,.05);
        cursor: pointer;
    }
    
    #my-resume .resume-box .resume-box-title {
       line-height: 66px;
       font-size: 48px;
       color: #24272e;
       font-weight: 500;
    }

    #my-resume .resume-box-content .col {
        display: flex;
        flex-direction: column;
    }

    #my-resume .resume-box-content .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    #my-resume .field-read-item {
        display: flex!important;
        flex-direction: row!important;
        line-height: 64px;
    }

    #my-resume .field-read-item .field-title {
        color: #b1b1b1;
        margin-right: 4px;
    }

    #my-resume .field-read-item .field-title.h3 {
        color: #414a60;
        font-size: 30px;
        font-weight: 700;
    }

    #my-resume .field-read-item .field-value {
        color: #414a60;
        max-width: 60vw;
        word-wrap: break-word;      /* IE 5.5-7 */
        white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
        white-space: pre-wrap;      /* current browsers */
    }

    #my-resume .tabview .field-item .field-value .row {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    #my-resume .tabview .field-item .field-value .row .divider {
        display: inline-block;
        width: 40px;
        text-align: center;
    }

    #my-resume .field-read-item .field-value.hint {
        font-size: 24px;
        padding-left: 30px;
        color: #9fa3b0;
    }

    #my-resume .resume-box-content .divider {
        width: 1px;
        height: 24px;
        background-color: #e0e0e0;
        margin: 20px;
    }

    #my-resume .resume-box,
    #my-resume .step-item {
        background-color: #fff;
        padding: 15px 30px;
        cursor: pointer;
    }
    
    #my-resume .step-item .resume-box-head {
        position: relative;
        padding-left: 20px;
        font-size: 36px;
        height: 50px;
        line-height: 50px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    #my-resume .step-item .resume-box-content {
        padding: 10px 14px;
        min-height: 80px;
    }

    #my-resume .resume-box-content:hover {
        position: relative;
    }

    #my-resume .resume-box-edit {
        display: none;
    }

    #my-resume .resume-box:hover .resume-box-edit,
    #my-resume .resume-box-content:hover .resume-box-edit {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        position: absolute;
        right: 0;
    }
    #my-resume .resume-box:hover .resume-box-edit {
        right: 30px;
        bottom: 15px;
    }

    #my-resume .resume-box-edit a {
        background-image: url(../../../../assets/imgs/pc/jobs/icon_edit_resume.png);
        background-repeat: no-repeat;
        background-position: 0;
        background-size: 32px 32px;
        padding-left: 36px;
        font-size: 30px;
        display: inline-block;
        line-height: 36px;
        color: #00c2b3;
    }
    
    #my-resume .resume-box-edit a.btn-del {
        background-image: url(../../../../assets/imgs/pc/jobs/icon_del_resume.png);
        background-repeat: no-repeat;
        background-position: 0;
        background-size: 32px 32px;
        padding-left: 36px;
        font-size: 24px;
    }

    #my-resume .resume-box-edit a:last-of-type {
        margin-left: 24px;
        margin-right: 24px;
    }

    #my-resume .resume-box-edit a:hover > span {
        opacity: 0.5;
    }

    #my-resume .step-item .resume-box-title {
        font-size: 36px;
        height: 50px;
        line-height: 50px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    #my-resume .step-item .resume-box-head::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 30px;
        border-radius: 2px;
        background-color: #53cac3;
        left: 0;
        top: 5px;
    }

    #my-resume .step-item .add-step-item {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 32px;
        font-weight: 400;
        color: #00c2b3;
        padding-left: 32px;
        background-image: url(../../../../assets/imgs/pc/jobs/icon_add_resume.png);
        background-size: 32px 32px;
        background-repeat: no-repeat;
        background-position: 0;
    }

    #my-resume .step-item:hover {
        background-color: #f8f9fb;
    }

    #my-resume .step-item .resume-box-content:hover {
        background-color: #fff;
        box-shadow: 0 2px 14px 0 rgba(0,0,0,.05);
        border-radius: 4px;
    }

    #my-resume .step-item:hover .add-step-item {
        display: block;
        float: right;
    }

    #my-resume .tabview {
        min-height: 500px;
        display: flex;
        padding-top: 80px;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background-color: #f8f9fb;
    }

    #my-resume .tabview .header {
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 0;
        top: 15px;
    }

    #my-resume .tabview .header .title {
        font-size: 32px;
        height: 88px;
        font-weight: 500;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 42px 0px 42px;
        box-sizing: border-box;
    }

    #my-resume .tabview .field-list {
        display: flex;
        flex-wrap: wrap;
        padding: 24px 42px 0px 42px;
        justify-content: space-between;
    }

    #my-resume .tabview .field-item {
        color: #495060;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        width: calc(50% - 24px);
    }

    #my-resume .tabview .field-item .btn-add-credentials-file {
        height: 60px;
        line-height: 60px;
        text-decoration: underline;
        cursor: pointer;
    }

    #my-resume .tabview .field-item.field-item.full-line {
        width: 100%;
    }

    #my-resume .tabview .field-item .range-value {
        display: flex;
        flex-direction: row;
    }

    #my-resume .tabview .field-item .range-value .label {
        width: 100px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #my-resume .tabview .field-item .field-label .required {
        color: #f00;
    }

    #my-resume .tabview .field-item .field-value {
        margin-top: 16px;
        min-height: 80px;
        border: 1px solid #e3e7ed;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
    }
    #my-resume .tabview .field-item .field-value.radio {
        border-color: transparent;
    }

    #my-resume .tabview .field-item .range-value .field-value {
        flex: 1;
    }

    #my-resume .tabview .field-item .field-value > input {
        background-color: transparent;
        width: 100%;
        padding: 24px 16px;
        box-sizing: border-box;
    }

    #my-resume .tabview .field-item .field-value > input:read-only {
        background-color: #f3f3f3;
    }

    #my-resume .tabview .field-item .field-value > select {
        background-color: transparent;
        outline: none;
        border: none;
        display: block;
        width: 100%;
        padding: 24px 16px;
        box-sizing: border-box;
    }

    #my-resume .tabview .field-item .field-value > textarea {
        background-color: transparent;
        outline: none;
        border: none;
        display: block;
        width: 100%;
        padding: 24px 16px;
        box-sizing: border-box;
    }

    #my-resume .tabview .ant-calendar-picker {
        width: 100%;
    }
    
    #my-resume .tabview .ant-input {
        height: unset;
        border: none;
        height: 86px;
        box-sizing: border-box;
    }

    #my-resume .tabview .ant-radio-group {
        line-height: 86px;
        height: 86px;
        padding: 0px 16px;
    }

    #my-resume .tabview .operater {
        display: flex;
        flex-direction: row;
        width: 100vw;
        box-sizing: border-box;
        padding: 40px 82px;
        align-items: flex-end;
        justify-content: flex-end;
    }
    #my-resume .tabview .operater .btn {
        margin: 0;
        padding: 18px 30px;
        line-height: 1;
        font-size: 28px;
        background-color: #fff;
        border: 1px solid #e3e7ed;
        color: #414a60;
        vertical-align: middle;
        cursor: pointer;
        text-align: center;
        border-radius: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        transition: background-color .1s linear,box-shadow .1s linear;
        outline: none;
        user-select: none;
    }

    #my-resume .tabview .operater .btn-cancel {
        margin-right: 20px;
        min-width: 160px;
        background-color: #fff;
    }

    #my-resume .tabview .operater .btn-confirm {
        margin-right: 20px;
        min-width: 160px;
        background-color: #6adbcf;
        border-color: #6adbcf;
        color: #fff;
    }

    #my-resume .field-item .field-value .van-radio-group {
        display: flex;
        flex-direction: row;
        padding: 12px 8px;
    }

    #my-resume .field-item .field-value .van-radio-group  .van-radio {
        margin-right: 12px;
    }

    #my-resume .field-item .field-value .van-cell {
        padding: 12px 8px;
    }
</style>
